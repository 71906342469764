import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TrainingService } from "src/app/training.service";
import { catchError, of, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class SchoolResolver implements Resolve<any> {
  constructor(
    private trainingService: TrainingService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const orgId = route.queryParamMap.get("org") == null ? "DEFAULT" : route.queryParamMap.get("org");

    return this.trainingService.load().pipe(
      switchMap((api) => {
        return this.httpClient.get(
          api.getRoute("schoolCodeUrl").replace("{code}", orgId)
        );
      }),
      catchError(() => {
        this.router.navigate(["/login"]);
        return of(null);
      })
    );
  }
}
